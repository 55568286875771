import glsl from "glslify";

export default glsl`

// Change these values to change the effect
#define DISPLACEMENT_STRENGTH 0.75;

#ifdef GL_ES
precision mediump float;
#endif

uniform sampler2D tDiffuse;
uniform sampler2D mouseTexture;
uniform float uTime;
uniform vec2 uResolution;

varying vec2 vUv;

void main()
{
  vec2 origuv = vUv;
  vec2 uvForP = origuv;
  vec2 uv = ((gl_FragCoord.xy / uResolution.xy)-0.5)/vec2(uResolution.y / uResolution.x, 1);

  // we don't use the time uniform, but we can use it to animate the effect if needed
  float time = 1.0;

  vec3 textDisp = texture2D(mouseTexture, origuv).xyz + vec3(0.5);
  uvForP -= (textDisp[0]-0.5)*sin(time*1.0)*DISPLACEMENT_STRENGTH;
  
  uv.y += sin(((uv.x+time)*0.75)*0.75);

  vec3 textCol = texture2D(tDiffuse, uvForP).xyz;

  vec4 p = vec4(textCol.xyz,1.0);

	gl_FragColor = p;
}

`;
