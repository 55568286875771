import glsl from "glslify";
import * as THREE from "three";
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer";
import fragmentShader from "./fragmentShader";

let gpuCompute = null;
let mouseTexture = null;
let gpuVariable = null;

const values = {
  drawing: false,
  started: false,
  width: window.innerWidth,
  height: window.innerHeight,
};

export function onFrame(elapsedTime) {
  gpuCompute.compute();
  const texture = gpuCompute.getCurrentRenderTarget(gpuVariable).texture;
  gpuVariable.material.uniforms.time.value = elapsedTime;
  gpuVariable.material.uniforms.inputTexture.value = texture;
  return texture;
}

export function onStartBath() {
  values.started = true;
}

export function onStopBath() {
  values.started = false;
}

export function init(renderer, sizes) {
  gpuCompute = new GPUComputationRenderer(sizes.width, sizes.height, renderer);
  mouseTexture = gpuCompute.createTexture();
  const mouseTextureArray = mouseTexture.image.data;
  for (let i = 0; i < mouseTextureArray.length; i += 4) {
    mouseTextureArray[i] = 0;
    mouseTextureArray[i + 1] = 0;
    mouseTextureArray[i + 2] = 0;
    mouseTextureArray[i + 3] = 1;
  }
  gpuVariable = gpuCompute.addVariable(
    "mouseTexture",
    fragmentShader,
    mouseTexture
  );
  gpuVariable.material.uniforms.time = { value: 0 };
  gpuVariable.material.uniforms.mouseX = { value: -100 };
  gpuVariable.material.uniforms.mouseY = { value: -100 };
  gpuVariable.material.uniforms.inputTexture = { value: mouseTexture };
  gpuVariable.material.uniforms.isDrawing = { value: 0.5 };
  gpuCompute.setVariableDependencies(gpuVariable, [gpuVariable]);

  const errors = gpuCompute.init();

  document.addEventListener("touchmove", (e) => {
    if (!values.started) return e.preventDefault();
    const x = e.touches[0].pageX;
    const y = window.innerHeight - e.touches[0].pageY;
    gpuVariable.material.uniforms.mouseX.value = x;
    gpuVariable.material.uniforms.mouseY.value = y;
  });

  document.addEventListener("touchstart", (e) => {
    if (!values.started) return e.preventDefault();
    const x = e.touches[0].pageX;
    const y = window.innerHeight - e.touches[0].pageY;
    gpuVariable.material.uniforms.mouseX.value = x;
    gpuVariable.material.uniforms.mouseY.value = y;
    gpuVariable.material.uniforms.isDrawing.value = 1.0;
    values.drawing = true;
  });

  document.addEventListener("touchend", (e) => {
    gpuVariable.material.uniforms.mouseX.value = -100;
    gpuVariable.material.uniforms.mouseY.value = -100;
    gpuVariable.material.uniforms.isDrawing.value = 0.0;
    values.drawing = false;
  });
}
