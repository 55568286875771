import * as THREE from "three";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import vertexShader from "./vertexShader";
import fragmentShader from "./fragmentShader";

const shader = {
  uniforms: {
    tDiffuse: { value: null },
    uTime: { value: null },
    uResolution: { value: null },
    mouseTexture: { value: null },
  },
  vertexShader,
  fragmentShader,
};

export function init(sizes) {
  const shaderPass = new ShaderPass(shader);
  shaderPass.material.uniforms.uTime.value = 0;
  shaderPass.material.uniforms.uResolution.value = new THREE.Vector2(
    sizes.width,
    sizes.height
  );

  shaderPass.onStartBath = () => {};
  shaderPass.onStopBath = () => {};
  shaderPass.onFrame = (elapsedTime, texture) => {
    shaderPass.material.uniforms.uTime.value = elapsedTime;
    shaderPass.material.uniforms.mouseTexture.value = texture;
  };

  return shaderPass;
}
