import GUI from "lil-gui";

import "./style.css";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import Swiper, { Pagination } from "swiper";
import "swiper/css";

import Stats from "three/examples/jsm/libs/stats.module.js";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";

import * as GPUComputation from "./shaders/GPUComputation/GPUComputation";
import * as DrawingShader from "./shaders/DrawingShader/DrawingShader";

import loadTeaObject from "./loadTeaObject";

//stats

var stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
//document.body.appendChild(stats.dom);

//sounds
const alarm = new Audio("sounds/alerte2.mp3");
const sound = new Audio("sounds/sound_def.mp3");
const sound_bambou = new Audio("sounds/meditation-lebruitdesvagues.mp3");


const tracks = [
  { start: 0, end: 153 },
  { start: 153, end: 303 },
  { start: 303, end: 453 },
  { start: 453, end: 600 },
];

var nTrack = 0;

const showDuration = () => {
  var soundD = tracks[3].end;
  var extra = soundD % 60 < 10 ? "0" : "";
  document.querySelector(".player-duration").innerHTML =
    Math.floor(soundD / 60) + ":" + extra + (soundD % 60);
};

showDuration();

//variables

var Simplex = require("perlin-simplex");
var simplex = new Simplex();
var time = Math.random() * 1000;
var soundTime = 0;
var countdown = 300;
var soundStart = false;
var soundBambouStart = false;
var soundPlaying = false;
var soundBambouPlaying = false;
var minuteurPlaying = false;
var infusionReady = false;
var memoScroll = 0;
var navIndex;
var directScroll = false;
var bathPlaying = false;
var firstStart = true;
var silence_mode = false;
var watchMode = false;

var nScale0 = 0;
var nScale1 = 0.1;
var nScale2 = 0.1;
var nScale3 = 0.1;
var nScale4 = 0;
var rpBase = 0;
var zoom = 0;

var prgss = 0;

var bkgColor = new THREE.Color();
var fogColor = new THREE.Color();

const bkgColors = [0x64c4a5, 0xf0c0d7, 0xee4643, 0xfcda6f];

const fogColors = [0x1b7dad, 0x90bddf, 0xf8a14f, 0xe78848];

var topColor = new THREE.Color(bkgColors[0]);
var topFog = new THREE.Color(fogColors[0]);
var bottomColor = new THREE.Color(bkgColors[1]);
var bottomFog = new THREE.Color(fogColors[1]);

const gui = new GUI();

const myObject = {
  scale: 0.005,
  top: 0.072,
  rotationY: 0,
  rotationX: 0,
  rotationZ: 0,
  igrek: 0.14,
  scaleY: 0.38,
};

var mainBox = new THREE.Group();
var box = new THREE.Group();
var sourceBox = new THREE.Object3D();

var infusion = new THREE.Group();
var boxReady = false;

var cTime1 = [10, 10, 40, 30, 10];
var cTime2 = [10, 15, 30, 15, 30, 10];
var cTime3 = [10, 15, 15, 20, 10];

// Tools
const lerp = (x, y, a) => x * (1 - a) + y * a;
const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x, y, a) => clamp((a - x) / (y - x));
const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));

//DOM
const nav = document.querySelector("nav");
const main = document.querySelector("main");
const bruit = document.querySelector(".bruit");

const pl0 = document.querySelector(".pl0");
const pl1 = document.querySelector(".pl1");
const pl2 = document.querySelector(".pl2");
const pl3 = document.querySelector(".pl3");
const pl4 = document.querySelector(".pl4");
const pl5 = document.querySelector(".pl5");

const iconPlayer = document.querySelector(".icon-player");
const timelineSound = document.querySelector(".player-timeline");
const cursorSound = document.querySelector(".player-cursor");
const playerTime = document.querySelector(".player-time");
const player = document.querySelector(".player");

const sections = document.querySelectorAll("section");
const navLinks = document.querySelectorAll(".nav-links a");

const minTime = document.querySelector(".min-time");
const minTimeInside = document.querySelector(".min-time-inside");

const flowerCards = document.querySelectorAll(".flower-card");
const flowerRound = document.querySelector(".swiper-wrapper-round");

const lavande1 = document.querySelector(".prlx_lavande");
const lavande2 = document.querySelector(".prlx_lavande2");
const orange1 = document.querySelector(".prlx_orange");
const orange2 = document.querySelector(".prlx_orange2");
const vanille = document.querySelector(".prlx_vanille");
const tasse = document.querySelector(".prlx_tasse");

const lavande_img = document.querySelector(".smell_lavande1");
const lavande_img2 = document.querySelector(".smell_lavande2");
const orange_img = document.querySelector(".smell_orange1");
const orange_img2 = document.querySelector(".smell_orange2");
const vanille_img = document.querySelector(".smell_vanille");

/*
const tasseVideo = document.querySelector('.tasse_video')
const tasseVideoBkg = document.querySelector('.tasse_video_bkg')
*/

var wh = document.querySelector(".sommaire").clientHeight;

/**
 * Loading manager
 */

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {},

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    const progressRatio = itemsLoaded / itemsTotal;
    //console.log(itemUrl, itemsLoaded, itemsTotal)
  }
);
const textureLoader = new THREE.TextureLoader(loadingManager);

// Canvas
const canvas = document.querySelector(".main-canvas");
const canvasBack = document.querySelector(".back-canvas");
const canvasLast = document.querySelector(".canvas-last");

//vectors

const threeMiddle = new THREE.Vector3(0, 0, 0);
const target = new THREE.Vector3(0, 0, 0);

// Scene
const scene = new THREE.Scene();
const sceneBack = new THREE.Scene();
const sceneLast = new THREE.Scene();

sceneBack.fog = new THREE.Fog(fogColors[0], 0.5, 2.5);

/**
 * Sizes
 */
var sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

/**
 * Cameras
 */
const camera = new THREE.PerspectiveCamera(
  25,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.z = 0.7;
camera.position.y = 0;
target.y = camera.position.y;

mainBox.position.y = -0.3;

scene.add(camera);

var FOV = sizes.width > sizes.height ? 12 : 20;
const cameraBack = new THREE.PerspectiveCamera(
  FOV,
  sizes.width / sizes.height,
  0.1,
  100
);
cameraBack.position.z = 1.5;

sceneBack.add(cameraBack);

const cameraLast = new THREE.PerspectiveCamera(
  20,
  sizes.width / sizes.height,
  0.1,
  100
);
cameraLast.position.z = 0.7;
sceneLast.add(cameraLast);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true,
});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(window.devicePixelRatio);

const rendererBack = new THREE.WebGLRenderer({
  canvas: canvasBack,
  alpha: false,
  antialias: true,
});

rendererBack.setSize(sizes.width, sizes.height);
rendererBack.setPixelRatio(1);

const rendererLast = new THREE.WebGLRenderer({
  canvas: canvasLast,
  alpha: true,
  antialias: true,
});

rendererLast.setSize(sizes.width, sizes.height);
rendererLast.setPixelRatio(window.devicePixelRatio);

window.addEventListener("resize", onWindowResize);

function onWindowResize() {
  sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  wh = document.querySelector(".sommaire").clientHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);

  cameraBack.aspect = sizes.width / sizes.height;
  cameraBack.updateProjectionMatrix();
  rendererBack.setSize(sizes.width, sizes.height);

  cameraLast.aspect = sizes.width / sizes.height;
  cameraLast.updateProjectionMatrix();
  rendererLast.setSize(sizes.width, sizes.height);

  var FOV = sizes.width > sizes.height ? 15 : 20;

  cameraBack.fov = FOV;
  cameraBack.updateProjectionMatrix();
}

/**
 * Mouse
 */
/*const mouse = new THREE.Vector3(0, 0, 0);

document.addEventListener("touchmove", (e) => {
  if (watchMode) {
    var iks = e.touches[0].pageX;
    var igrek = e.touches[0].pageY;

    mouse.x = (iks / sizes.width) * 2 - 1;
    mouse.y = -(igrek / sizes.height) * 2 + 1;

    updateData(mouse.x, mouse.y);
  }
});*/

/**
 * Post processing
 */
const effectComposer = new EffectComposer(rendererBack);
effectComposer.setSize(sizes.width, sizes.height);
effectComposer.setPixelRatio(1);

const renderPass = new RenderPass(sceneBack, cameraBack);
effectComposer.addPass(renderPass);

const drawingShader = DrawingShader.init(canvas);

GPUComputation.init(rendererBack, sizes);

effectComposer.addPass(drawingShader);
drawingShader.renderToScreen = true;

//memo

var memoX, memoY;

const width = 256;
const height = 256;

const size = width * height;
var data = new Uint8Array(4 * size);

const r = 127;
const g = 127;
const b = 255;

for (let i = 0; i < size; i++) {
  const stride = i * 4;

  data[stride] = r;
  data[stride + 1] = g;
  data[stride + 2] = b;
  data[stride + 3] = 255;
}

// used the buffer to create a DataTexture

var texture = new THREE.DataTexture(data, width, height);
texture.needsUpdate = true;

const updateData = (x, y) => {
  var vX = Math.round(range(0.05, -0.05, 0, 255, x - memoX));
  var vY = Math.round(range(0.05, -0.05, 0, 255, y - memoY));

  var point = new THREE.Vector3(
    range(-1, 1, 0, width, x),
    range(-1, 1, 0, height, y),
    0
  );

  for (let i = 0; i < size; i++) {
    const stride = i * 4;
    var iks = Math.floor(i % width);
    var igrek = Math.floor(i / height);

    var pixel = new THREE.Vector3(iks, igrek, 0);
    var v = range(0, 25, 1, 0, point.distanceTo(pixel));

    if (v > 0) {
      data[stride] = Math.floor(lerp(data[stride], vX, 0.5));
      data[stride + 1] = Math.floor(lerp(data[stride + 1], vY, 0.5));
    }
  }

  texture = new THREE.DataTexture(data, width, height);
  texture.needsUpdate = true;
  displacementPass.material.uniforms.uNormalMap.value = texture;

  memoX = x;
  memoY = y;
};

const resetData = () => {
  for (let i = 0; i < size; i++) {
    const stride = i * 4;

    if (data[stride] > 127) {
      data[stride] -= 1;
    }

    if (data[stride] < 127) {
      data[stride] += 1;
    }

    if (data[stride + 1] > 127) {
      data[stride + 1] -= 1;
    }

    if (data[stride + 1] < 127) {
      data[stride + 1] += 1;
    }
  }

  texture = new THREE.DataTexture(data, width, height);
  texture.needsUpdate = true;
  displacementPass.material.uniforms.uNormalMap.value = texture;
};

//tint pass

const DisplacementShader = {
  uniforms: {
    tDiffuse: { value: null },
    uTime: { value: null },
    uNormalMap: { value: null },
    iks: { value: null },
    igrek: { value: null },
  },
  vertexShader: `
        varying vec2 vUv;
        varying vec3 vPosition;


        void main()
        {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            vUv = uv;
            vPosition=position;
        }
    `,
  fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform sampler2D uNormalMap;

        uniform float uTime;
        varying vec3 vPosition;
        uniform float iks;
        uniform float igrek;   
   
        varying vec2 vUv;

        void main()
        {
            vec3 mouse=vec3(iks,igrek,0);
            float dist=length(vPosition-mouse);


            vec3 normalColor=texture2D(uNormalMap,vUv).xyz*2.0-1.0;
            vec2 newUv = vUv+normalColor.xy*.3;
            vec4 color = texture2D(tDiffuse, newUv);
            vec4 testColor=texture2D(uNormalMap,vUv);

            //gl_FragColor=testColor;
            gl_FragColor=color;
        }
    `,
};

const displacementPass = new ShaderPass(DisplacementShader);
displacementPass.material.uniforms.uTime.value = 0;
displacementPass.material.uniforms.iks.value = 0;
displacementPass.material.uniforms.igrek.value = 0;
displacementPass.material.uniforms.uNormalMap.value = texture;

effectComposer.addPass(displacementPass);

/**
 * Orbit controls
 */
/*
const controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
*/

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 1.2);
scene.add(ambientLight);

/**
 * Textures
 */

const silverTexture = textureLoader.load("textures/matcap_silver.webp");
const sideTexture = textureLoader.load(
  "textures/UV-le-bruit-des-vagues-cote.webp"
);
const capTexture = textureLoader.load(
  "textures/uv-le-bruit-des-vagues-haut.webp"
);

const sideTexture2 = textureLoader.load("textures/side_vague.webp");
const capTexture2 = textureLoader.load("textures/top_vague.webp");

const sideTexture3 = textureLoader.load("textures/side_joie.webp");
const capTexture3 = textureLoader.load("textures/top_joie.webp");

const sideTexture4 = textureLoader.load("textures/side_bulle.webp");
const capTexture4 = textureLoader.load("textures/top_bulle.webp");

const sideTexture5 = textureLoader.load("textures/side_nuage.webp");
const capTexture5 = textureLoader.load("textures/top_nuage.webp");

const vracTexture = textureLoader.load("textures/vrac3.webp");
const vracNormal = textureLoader.load("textures/vrac_normal.webp");

const elipseTexture = textureLoader.load("textures/bath/elipse.webp");
const elipse2Texture = textureLoader.load("textures/bath/elipse2.webp");
const pinkTexture = textureLoader.load("textures/bath/pink.webp");
const roundTexture = textureLoader.load("textures/bath/round.webp");
const roundPinkTexture = textureLoader.load("textures/bath/roundPink.webp");
const waveTexture = textureLoader.load("textures/bath/wave2.webp");

const cloudTexture1 = textureLoader.load("textures/wave1.webp");
const cloudTexture2 = textureLoader.load("textures/wave2.webp");
const cloudTexture3 = textureLoader.load("textures/wave3.webp");

sideTexture.flipY = false;
capTexture.flipY = false;
sideTexture2.flipY = false;
capTexture2.flipY = false;
sideTexture3.flipY = false;
capTexture3.flipY = false;
sideTexture4.flipY = false;
capTexture4.flipY = false;
sideTexture5.flipY = false;
capTexture5.flipY = false;

vracTexture.flipY = true;

/**
 * Material
 */
//const gold = new THREE.MeshMatcapMaterial({ matcap: goldTexture });
const debug = new THREE.MeshNormalMaterial({ wireframe: false });
const silver = new THREE.MeshMatcapMaterial({ matcap: silverTexture });

const pink = new THREE.MeshBasicMaterial({ color: 0xf0c0d7, wireframe: false });

const side = new THREE.MeshBasicMaterial({ map: sideTexture });
const cap = new THREE.MeshBasicMaterial({ map: capTexture });

const vrac = new THREE.MeshStandardMaterial({
  map: vracTexture,
  transparent: true,
  displacementMap: vracNormal,
});

vrac.displacementScale = myObject.scale;
vrac.metalness = 0;
vrac.roughness = 2;

/**
 * Objects
 */
const geometry = new THREE.PlaneGeometry(1.2, 1.2, 64, 64);
const plan = new THREE.Mesh(
  geometry,
  new THREE.MeshBasicMaterial({ color: 0xf0c0d7 })
);
sceneBack.add(plan);

var vertices = plan.geometry.attributes.position.array;

for (var i = 0; i <= vertices.length; i += 3) {
  vertices[i + 2] =
    simplex.noise3d(vertices[i] * 3, vertices[i + 1] * 3, time) / 2 + 0.1;
}

// bath

const bathElipse1 = new THREE.Mesh(
  new THREE.PlaneGeometry(0.644, 0.316, 2, 2),
  new THREE.MeshBasicMaterial({
    map: elipseTexture,
    transparent: true,
    opacity: 1,
  })
);
bathElipse1.scale.set(0.5, 0.5, 0.5);
bathElipse1.position.z = 0.67;
bathElipse1.position.y = -0.3;
sceneBack.add(bathElipse1);

const bathElipse2 = new THREE.Mesh(
  new THREE.PlaneGeometry(1.079, 0.424, 2, 2),
  new THREE.MeshBasicMaterial({
    map: elipse2Texture,
    transparent: true,
    opacity: 1,
  })
);
bathElipse2.scale.set(0.5, 0.5, 0.5);
bathElipse2.position.z = 0.65;
bathElipse2.position.y = -0.4;
sceneBack.add(bathElipse2);

const bathPink = new THREE.Mesh(
  new THREE.PlaneGeometry(1.059, 0.46, 2, 2),
  new THREE.MeshBasicMaterial({
    map: pinkTexture,
    transparent: true,
    opacity: 1,
  })
);
bathPink.scale.set(0.5, 0.5, 0.5);
bathPink.position.z = 0.7;
bathPink.position.y = -0.5;
sceneBack.add(bathPink);

const bathRound = new THREE.Mesh(
  new THREE.PlaneGeometry(0.5, 0.5, 2, 2),
  new THREE.MeshBasicMaterial({
    map: roundTexture,
    transparent: true,
    opacity: 0,
  })
);
bathRound.scale.set(0.5, 0.5, 0.5);
bathRound.position.z = 0.8;
bathRound.position.x = -1;
bathRound.position.y = -1;
sceneBack.add(bathRound);

const bathRound2 = new THREE.Mesh(
  new THREE.PlaneGeometry(0.5, 0.355, 2, 2),
  new THREE.MeshBasicMaterial({
    map: roundTexture,
    transparent: true,
    opacity: 0,
  })
);
bathRound2.scale.set(0.5, 0.5, 0.5);
bathRound2.position.z = 0.85;
bathRound2.position.x = -1;
bathRound2.position.y = -1;
sceneBack.add(bathRound2);

var bathRP = [];

for (var i = 0; i < 6; i++) {
  bathRP.push(
    new THREE.Mesh(
      new THREE.PlaneGeometry(0.052, 0.036, 2, 2),
      new THREE.MeshBasicMaterial({
        map: roundPinkTexture,
        transparent: true,
        opacity: 0,
      })
    )
  );
  bathRP[i].position.z = 0.95;
  bathRP[i].position.x = 2;
  sceneBack.add(bathRP[i]);
}

const bathWave = new THREE.Mesh(
  new THREE.PlaneGeometry(1.5, 0.934, 2, 2),
  new THREE.MeshBasicMaterial({
    map: waveTexture,
    transparent: true,
    opacity: 0.8,
  })
);
bathWave.scale.set(0.5, 0.5, 0.5);
bathWave.position.z = 0.9;
bathWave.position.x = -0.25;
bathWave.position.y = -0.25;

sceneBack.add(bathWave);

// BOX
// Note that since Three release 148, you will find the Draco libraries in the `.\node_modules\three\examples\jsm\libs\draco\` folder.
//const dracoLoader = new DRACOLoader()
//dracoLoader.setDecoderPath('/draco/')

const loader = new GLTFLoader();

let GLBloader = new GLTFLoader();
//GLBloader.setDRACOLoader(dracoLoader)

var top_color;
var top_metal;

// box2
var box2 = new THREE.Object3D();
var box3 = new THREE.Object3D();
var box4 = new THREE.Object3D();

var bigBox2 = new THREE.Group();
var bigBox3 = new THREE.Group();
var bigBox4 = new THREE.Group();

var lastBoxes = new THREE.Group();
sceneLast.add(lastBoxes);

lastBoxes.add(bigBox2);
lastBoxes.add(bigBox3);
lastBoxes.add(bigBox4);

lastBoxes.position.y = -0.51;

GLBloader.load("objects/test7.glb", function (gltf) {
  //console.log('..... OBJET LOADED .....')

  gltf.scene.traverse(function (child) {
    //console.log(child.name)
    if (child.name == "boite_imprimé") {
      child.material = side;
    } else if (child.name == "capuchon_imprimé") {
      child.material = cap;
      top_color = child;
      top_color.position.y = myObject.top;
    } else if (child.name == "capuchon_metal") {
      child.material = silver;
      top_metal = child;
      top_metal.position.y = myObject.top;
    } else if (child.name == "thé") {
      child.material = vrac;
      child.position.y -= 0.002;
    } else {
      child.material = silver;
    }
  });

  sourceBox = gltf.scene;

  box.add(sourceBox);
  infusion.add(box);

  boxReady = true;

  document.querySelector(".cta-start").classList.remove("hide");
  document.querySelector(".burger-menu").classList.remove("hide");

  // last box 2

  box2 = sourceBox.clone();
  box2.scale.set(0.60, 0.60, 0.60);
  box2.rotation.y = Math.PI / 6;
  bigBox2.add(box2);
  bigBox2.position.y = Math.cos(0) * 0.5 + 0.025;
  bigBox2.position.x = Math.sin(0) * 0.5;

  box2.children.forEach((child) => {
    if (child.name == "boite_imprimé") {
      child.material = new THREE.MeshBasicMaterial({ map: sideTexture5 });
      child.rotation.y = -Math.PI / 6;
    } else if (child.name == "capuchon_imprimé") {
      child.material = new THREE.MeshBasicMaterial({ map: capTexture5 });
      child.rotation.y = -Math.PI / 6;
    } else if (child.name == "capuchon_metal") {
      child.material = silver;
    } else {
      child.material = silver;
    }
  });

  // last box 3

  box3 = sourceBox.clone();
  box3.scale.set(0.60, 0.60, 0.60);
  box3.rotation.y = Math.PI / 6;
  bigBox3.add(box3);

  bigBox3.position.y = Math.cos(Math.PI * 0.06) * 0.5 + 0.025;
  bigBox3.position.x = Math.sin(Math.PI * 0.06) * 0.5 + 0.004;
  bigBox3.rotation.z = -Math.PI * 0.06;

  box3.children.forEach((child) => {
    if (child.name == "boite_imprimé") {
      child.material = new THREE.MeshBasicMaterial({ map: sideTexture3 });
      child.rotation.y = Math.PI / 6;
    } else if (child.name == "capuchon_imprimé") {
      child.material = new THREE.MeshBasicMaterial({ map: capTexture3 });
      child.rotation.y = Math.PI / 6;
    } else if (child.name == "capuchon_metal") {
      child.material = silver;
    } else {
      child.material = silver;
    }
  });

  // last box 4

  box4 = sourceBox.clone();
  box4.scale.set(0.60, 0.60, 0.60);
  box4.rotation.y = Math.PI / 6;
  bigBox4.add(box4);

  bigBox4.position.y = Math.cos(Math.PI * 0.12) * 0.5 + 0.023;
  bigBox4.position.x = Math.sin(Math.PI * 0.12) * 0.5 + 0.009;
  bigBox4.rotation.z = -Math.PI * 0.12;

  box4.children.forEach((child) => {
    if (child.name == "boite_imprimé") {
      child.material = new THREE.MeshBasicMaterial({ map: sideTexture4 });
      child.rotation.y = (Math.PI / 3) * 1.5;
    } else if (child.name == "capuchon_imprimé") {
      child.material = new THREE.MeshBasicMaterial({ map: capTexture4 });
      child.rotation.y = (Math.PI / 3) * 1.5;
    } else if (child.name == "capuchon_metal") {
      child.material = silver;
    } else {
      child.material = silver;
    }
  });
});

mainBox.add(infusion);
scene.add(mainBox);

/*const vracBall = new THREE.Group();
box.add(vracBall)

const vracGeo = new THREE.SphereBufferGeometry(.029, 64, 64);
const vracBkg = new THREE.Mesh(vracGeo, vrac);
vracBall.add(vracBkg);


vracBkg.rotation.z = Math.PI / 6

// vracBkg.rotation.x = Math.PI / 2
// vracBkg.rotation.z = -Math.PI / 3

vracBkg.position.y = myObject.igrek

vracBall.scale.set(1, myObject.scaleY, 1)
*/

loadTeaObject(box, myObject);

/*
gui.add(myObject, 'scaleY', 0, 1)
.name('scaleY')
.onChange(value => {
    vracBall.scale.set(1,value,1)
});

gui.add(myObject, 'igrek', 0, 1)
.name('igrek')
.onChange(value => {
    vracBkg.position.y=value
});

gui.add(myObject, 'scale', 0, .1)
.name('scale')
.onChange(value => {
    vrac.displacementScale = value
});
*/

// nuages

const geoCloud1 = new THREE.PlaneGeometry(1, 0.489, 4, 4);
const nuage1 = new THREE.Mesh(
  geoCloud1,
  new THREE.MeshBasicMaterial({
    map: cloudTexture1,
    transparent: true,
    opacity: 0.3,
  })
);
scene.add(nuage1);

nuage1.scale.set(0.7, 0.7, 0.7);
nuage1.position.z = -0.1;
nuage1.position.x = 0.07;

const geoCloud2 = new THREE.PlaneGeometry(1, 0.523, 4, 4);
const nuage2 = new THREE.Mesh(
  geoCloud2,
  new THREE.MeshBasicMaterial({
    map: cloudTexture2,
    transparent: true,
    opacity: 0.3,
  })
);
scene.add(nuage2);

nuage2.scale.set(0.5, 0.5, 0.5);
nuage2.position.z = 0.1;

const geoCloud4 = new THREE.PlaneGeometry(1, 0.437, 4, 4);
const nuage4 = new THREE.Mesh(
  geoCloud4,
  new THREE.MeshBasicMaterial({
    map: cloudTexture3,
    transparent: true,
    opacity: 0.25,
  })
);
scene.add(nuage4);

nuage4.scale.set(0.5, 0.5, 0.5);
nuage4.position.z = -0.1;
nuage4.position.x = -0.05;
/**
 * Animate
 */

var deb = 0;
var tick = 0;

const clock = new THREE.Clock();

function animate() {
  stats.begin();

  const elapsedTime = clock.getElapsedTime();

  tick++;

  if (watchMode) {
    if (tick % 5 == 0) {
      resetData();
    }
  } else {
    resetData();
  }

  var scr = main.scrollTop;
  var trigger = Math.round((scr / wh) * 10000) / 10000;

  if (!soundStart && trigger > 2.8) {
    player.classList.remove("hide");
  }

  if (!soundStart && trigger < 2.8) {
    player.classList.add("hide");
  }

  //nuages
  if (trigger < 2) {
    nuage1.position.y = range(0, 2.2, -0.35, 0.35, trigger);
    nuage2.position.y = range(0, 1.6, -0.3, 0.3, trigger);
    nuage4.position.y = range(0.6, 2, -0.3, 0.3, trigger);
  }

  if (trigger > 2 && trigger < 4) {
    nuage1.position.y = range(2, 4, -0.3, 0.35, trigger);
    nuage2.position.y = range(2, 4, -0.3, 0.3, trigger);
    nuage4.position.y = range(2, 4, -0.3, 0.3, trigger);
  }

  if (trigger > 4 && trigger < 6) {
    nuage1.position.y = range(4, 6, -0.3, 0.35, trigger);
    nuage2.position.y = range(4, 6, -0.2, 0.25, trigger);
    nuage4.position.y = range(4.2, 6, -0.3, 0.35, trigger);
  }

  if (trigger > 10 && trigger < 12) {
    nuage1.position.y = range(10, 12, -0.3, 0.35, trigger);
    nuage2.position.y = range(10, 12, -0.3, 0.35, trigger);
    nuage4.position.y = range(10, 12, -0.3, 0.35, trigger);
  }

  if (trigger > 12) {
    nuage1.position.y = 0.35;
    nuage2.position.y = 0.35;
    nuage4.position.y = 0.35;
  } else {
    nuage1.position.x = 0.2 * simplex.noise3d(0, 0, time / 3);
    nuage2.position.x = 0.2 * simplex.noise3d(0, 1500, time / 3);
    nuage4.position.x = 0.2 * simplex.noise3d(1500, 0, time / 3);
  }

  //savourez

  if (trigger > 5 && trigger < 8) {
    /*
        var angle_lavande = Math.round(simplex.noise3d(0, 0, time / 4) * 10);
        var angle_lavande2 = Math.round(simplex.noise3d(0, 500, time / 5) * 10);
        var angle_orange = Math.round(simplex.noise3d(0, 1000, time / 4) * 30);
        var angle_orange2 = Math.round(simplex.noise3d(1000, 0, time / 4) * 45);
        var angle_vanille = Math.round(simplex.noise3d(100, 100, time / 4) * 10);

        lavande_img.style.transform = ` translate(-80px,40px) rotate(${angle_lavande}deg)`;
        lavande_img2.style.transform = `translate(-10px,-140px) rotate(${angle_lavande2}deg)`;
        orange_img.style.transform = `translate(30px,80px) rotate(${angle_orange}deg)`;
        orange_img2.style.transform = `translate(-40%, 210px) rotate(${angle_orange2}deg)`;
        vanille_img.style.transform = ` translate(${range(7, 8, -10, -32, trigger)}px,420px) rotate(${angle_vanille}deg)`;
*/

    lavande1.style.transform = `translateY(${range(5.8, 7, 5, -30, trigger)}%)`;
    lavande2.style.transform = `translateY(${range(6, 7.6, 10, 0, trigger)}%)`;

    orange1.style.transform = `translateY(${range(6.2, 8, 25, -20, trigger)}%)`;
    orange2.style.transform = `translateY(${range(6.5, 8, 0, 10, trigger)}%)`;

    vanille.style.transform = `translateY( ${range(7, 8, 5, -10, trigger)}%)`;
    tasse.style.transform = `translateY(${range(6.7, 8, 0, -8, trigger)}%) `;
  }

  //intro

  if (trigger > 0 && trigger <= 0.7) {
    //canvas.classList.remove('hide')
    mainBox.position.y = range(0, 0.7, -0.3, 0, trigger);

    box.rotation.y = range(0, 0.7, 1.8, 0.56, trigger);
    box.rotation.x = range(0, 0.7, 0, -0.42, trigger);
    box.rotation.z = range(0, 0.7, 0, 0.25, trigger);
  }

  if (trigger > 0.7 && trigger <= 1) {
    box.rotation.y = range(0.7, 1, 0.56, -0.6, trigger);
  }

  if (trigger > 1 && trigger <= 2) {
    mainBox.position.y = range(1, 2, 0, -0.16, trigger);

    box.rotation.y = range(1, 2, -0.6, -Math.PI * 2 + 0.6, trigger);
    box.rotation.x = range(1, 2, -0.42, -0.15, trigger);
    box.rotation.z = range(1, 2, 0.25, -0.14, trigger);

    top_metal.position.y = 0.072;
    top_color.position.y = 0.072;
    top_metal.rotation.x = 0;
    top_color.rotation.x = 0;
  }

  //couvercle

  if (trigger > 2.2 && trigger <= 4) {
    mainBox.position.y = range(2.2, 3, -0.16, -0.07, trigger);

    box.rotation.x = range(2.2, 3, -0.15, 0.3, trigger);

    var value = range(2.2, 4.4, 0.072, 0.5, trigger);
    top_metal.position.y = value;
    top_color.position.y = value;

    top_metal.rotation.x = range(2.2, 4.4, 0, -1, trigger);
    top_color.rotation.x = range(2.2, 4.4, 0, -1, trigger);
  }

  if (trigger > 3.4 && trigger <= 4) {
    mainBox.position.y = range(3.4, 4, -0.07, -0.15, trigger);
  }

  if (trigger > 4 && trigger <= 9) {
    mainBox.position.y = range(4.5, 7, -0.15, 0.5, trigger);

    top_metal.position.y = 0.5;
    top_color.position.y = 0.5;
  }

  if (trigger > 9) {
    target.y = -0.5;
  }

  //last

  if (trigger > 10) {
    prgss = lerp(prgss, swiperLast.progress, 0.1);

    bruit.style.opacity = range(11, 12, 0.8, 0, trigger);

    box2.rotation.x = range(11, 12, 0.6, 0, trigger);
    box3.rotation.x = range(11, 12, 0.6, 0, trigger);
    box4.rotation.x = range(11, 12, 0.6, 0, trigger);

    lastBoxes.rotation.z = prgss * Math.PI * 0.12;
    box2.rotation.y = range(0, 1, Math.PI / 3, -Math.PI / 3, prgss);
    box3.rotation.y = range(0, 1, Math.PI / 3, -Math.PI / 3, prgss);
    box4.rotation.y = range(0, 1, Math.PI / 3, -Math.PI / 3, prgss);

    var color1 = new THREE.Color(bkgColors[1]);
    var color2 = new THREE.Color(bkgColors[2]);
    var color3 = new THREE.Color(bkgColors[3]);

    var colorf1 = new THREE.Color(fogColors[1]);
    var colorf2 = new THREE.Color(fogColors[2]);
    var colorf3 = new THREE.Color(fogColors[3]);

    if (prgss < 0.1 || prgss > 0.9 || (prgss > 0.45 && prgss < 0.55)) {
      document.querySelector(".cart").classList.add("ready");
    } else {
      document.querySelector(".cart").classList.remove("ready");
    }

    if (prgss < 0.5) {
      bottomColor.lerpColors(color1, color2, prgss * 2);
      bottomFog.lerpColors(colorf1, colorf2, prgss * 2);
    }
    if (prgss >= 0.5) {
      bottomColor.lerpColors(color2, color3, (prgss - 0.5) * 2);
      bottomFog.lerpColors(colorf2, colorf3, (prgss - 0.5) * 2);
    }

    if (document.querySelector(".vrac.active"))
      document.querySelector(".vrac.active").classList.remove("active");

    if (trigger > 11.7) {
      document
        .querySelectorAll(".vrac")
        [Math.round(range(0, 1, 0, 2, prgss))].classList.add("active");
    }

    bkgColor.lerpColors(topColor, bottomColor, range(11, 12, 0, 1, trigger));
    fogColor.lerpColors(topFog, bottomFog, range(11, 12, 0, 1, trigger));

    plan.material.color = bkgColor;
    sceneBack.fog.color = fogColor;
  } else {
    plan.material.color = new THREE.Color(bkgColors[0]);
    sceneBack.fog.color = new THREE.Color(fogColors[0]);
  }

  //

  /*
        camera.position.y = target.y
        camera.lookAt(target)
        */

  //controls.update();

  time += 0.005;
  displacementPass.material.uniforms.uTime.value = time * 10;

  var igrek = -scr / 500;

  bruit.style.backgroundPosition = "0 " + -scr + "px";

  for (var i = 0; i <= vertices.length; i += 3) {
    vertices[i + 2] =
      simplex.noise3d(vertices[i] * 3, vertices[i + 1] * 3 + igrek, time) / 2 +
      0.1;
  }

  plan.geometry.attributes.position.needsUpdate = true;
  plan.geometry.computeVertexNormals();

  if (trigger < 7 && trigger > 0) {
    infusion.rotation.x = (simplex.noise3d(0, 0, time / 8) * Math.PI) / 16;
    infusion.rotation.y = (simplex.noise3d(2000, 0, time / 4) * Math.PI) / 6;
    infusion.rotation.z = (simplex.noise3d(3000, 0, time / 8) * Math.PI) / 12;

    renderer.render(scene, camera);
  }

  if (trigger > 10) {
    rendererLast.render(sceneLast, cameraLast);
    canvasLast.classList.remove("hide");
  } else {
    canvasLast.classList.add("hide");
  }

  if (boxReady) {
    renderer.render(scene, camera);
    rendererLast.render(sceneLast, cameraLast);
  }

  //rendererBack.render(sceneBack, cameraBack);
  effectComposer.render();
  //sound

  if (sound.paused) {
    pl0.style.height = "30%";
    pl1.style.height = "30%";
    pl2.style.height = "30%";
    pl3.style.height = "30%";
    pl4.style.height = "30%";
    pl5.style.height = "30%";
  } else {
    soundTime += 0.04;

    pl0.style.height = range(-1, 1, 30, 100, Math.cos(soundTime)) + "%";
    pl1.style.height = range(-1, 1, 30, 100, Math.cos(soundTime + 0.5)) + "%";
    pl2.style.height = range(-1, 1, 30, 100, Math.cos(soundTime + 1)) + "%";
    pl3.style.height = range(-1, 1, 30, 100, Math.cos(soundTime + 1.5)) + "%";
    pl4.style.height = range(-1, 1, 30, 100, Math.cos(soundTime + 2)) + "%";
    pl5.style.height = range(-1, 1, 30, 100, Math.cos(soundTime + 2.5)) + "%";
  }

  //sound

  tracks.forEach((track, index) => {
    if (
      sound.currentTime >= tracks[index].start &&
      sound.currentTime < tracks[index].end
    ) {
      nTrack = index;
    }
  });

  if (nTrack == 3) {
    document.querySelector(".next").classList.add("disabled");
  } else {
    document.querySelector(".next").classList.remove("disabled");
  }

  if (nTrack == 0) {
    document.querySelector(".previous").classList.add("disabled");
  } else {
    document.querySelector(".previous").classList.remove("disabled");
  }

  if (sound.paused) {
    document.querySelector(".icon_play").classList.remove("hide");
    document.querySelector(".icon_pause").classList.add("hide");
    document.querySelector(".play").classList.remove("hide");
    document.querySelector(".pause").classList.add("hide");

    iconPlayer.classList.add("paused");
  } else {
    document.querySelector(".icon_play").classList.add("hide");
    document.querySelector(".icon_pause").classList.remove("hide");
    document.querySelector(".play").classList.add("hide");
    document.querySelector(".pause").classList.remove("hide");

    iconPlayer.classList.remove("paused");
  }

  var soundCT = Math.round(sound.currentTime);
  var duration = tracks[3].end;

  timelineSound.style.width = (sound.currentTime / duration) * 100 + "%";
  cursorSound.style.left = (sound.currentTime / duration) * 100 + "%";

  var extra = soundCT % 60 < 10 ? "0" : "";

  if (Math.floor(soundCT / 60) < 10) {
    playerTime.innerHTML =
      "0" + Math.floor(soundCT / 60) + ":" + extra + (soundCT % 60);
  } else {
    playerTime.innerHTML =
      Math.floor(soundCT / 60) + ":" + extra + (soundCT % 60);
  }

  //nav
  if (scr > memoScroll && !directScroll) {
    nav.classList.add("closed");
  }
  if (
    scr < memoScroll &&
    trigger < 10 &&
    !main.classList.contains("smooth") &&
    !watchMode
  ) {
    nav.classList.remove("closed");
  }

  if (directScroll) {
    directScroll = false;
  }

  memoScroll = scr;

  sections.forEach((section, index) => {
    if (
      section.getBoundingClientRect().top < 0 &&
      section.getBoundingClientRect().bottom > 0
    ) {
      navIndex = index;
    }
  });

  //bain sonore

  if (bathPlaying) {
    if (silence_mode) {
      var fbc_array = [];

      for (var b = 0; b < 16; b++) {
        fbc_array.push(
          (simplex.noise3d(500 * b, 0, time / 2) + 1) *
            255 *
            range(0, 16, 1, 0.4, b)
        );
      }
    } else {
      var fbc_array = new Uint8Array(analyser.frequencyBinCount);
      var bar_count = 16;
      analyser.getByteFrequencyData(fbc_array);
    }

    /*
        ctx.clearRect(0, 0, equa.width, equa.height);
        ctx.fillStyle = "#ffffff";

        for (var i = 0; i < bar_count; i++) {
            var bar_pos = i * equa.width / 16;
            var bar_width = 2;
            var bar_height = -(fbc_array[i] / 4);

            ctx.fillRect(bar_pos, equa.height, bar_width, bar_height / 2);

        }
*/

    //round

    nScale3 += 0.003;
    if (bathRound.material.opacity < 0.1 && nScale3 > 1 && fbc_array[8] > 10) {
      nScale3 = 0;
      bathRound.position.x = Math.random() * 0.1 - 0.05;
      bathRound.position.y = Math.random() * 0.2 - 0.1;
      console.log("round");
    }
    bathRound.scale.set(nScale3, nScale3, nScale3);
    bathRound.material.opacity =
      range(0, 255, 0, 1, fbc_array[8]) - nScale3 / 2;
    bathRound.rotation.z += 0.0008;

    //round2
    nScale4 += 0.001;
    if (bathRound2.material.opacity < 0.1 && nScale4 > 1 && fbc_array[2] > 10) {
      nScale4 = 0;
      bathRound2.position.x = Math.random() * 0.1 - 0.05;
      bathRound2.position.y = Math.random() * 0.2 - 0.1;

      console.log("round2");
    }
    bathRound2.scale.set(nScale4, nScale4, nScale4);
    bathRound2.material.opacity =
      range(0, 255, 0, 1, fbc_array[2]) - nScale4 / 2;
    bathRound2.rotation.z -= 0.001;

    //elipse
    nScale1 += 0.001;

    if (nScale1 > 0.6) {
      nScale1 = 0;
    }
    var o = range(0, 255, 0, 1, fbc_array[2]);
    bathElipse1.material.opacity = lerp(bathElipse1.material.opacity, o, 0.1);

    bathElipse1.position.y = -0.3 + nScale1;
    bathElipse1.position.x = 0.1 * simplex.noise3d(0, 0, time / 2);

    //elipse
    nScale0 += 0.0008;
    if (nScale0 > 0.6) {
      nScale0 = 0;
    }

    var o2 = range(0, 255, 0, 0.3, fbc_array[4]);

    bathElipse2.material.opacity = lerp(bathElipse2.material.opacity, o2, 0.1);
    bathElipse2.position.y = -0.3 + nScale0;
    bathElipse2.position.x = 0.1 * simplex.noise3d(500, 0, time / 2);

    nScale2 += 0.0005;
    if (nScale2 > 0.8) {
      nScale2 = 0;
    }

    var o3 = range(0, 255, 0, 0.7, fbc_array[10]);
    bathPink.material.opacity = lerp(bathPink.material.opacity, o3, 0.1);
    bathPink.position.y = -0.4 + nScale2;
    bathPink.position.x = 0.2 * simplex.noise3d(500, 500, time / 3);

    //wave
    bathWave.material.opacity = range(0, 255, 0.3, 0.9, fbc_array[7]);
    bathWave.position.x += 0.0005;
    bathWave.position.y += 0.0004;
    bathWave.rotation.z -= 0.00005;

    if (bathWave.position.y > 0.22) {
      bathWave.position.y = -0.22;
      bathWave.position.x = -0.25;
    }

    //pink small
    rpBase++;
    if (
      (rpBase > 160 && fbc_array[13] > 0 && !silence_mode) ||
      (silence_mode && rpBase > 160 && fbc_array[13] > 150)
    ) {
      rpBase = 0;
      console.log("small pink");
    }

    //console.log(fbc_array)

    if (rpBase == 0) {
      var angle = Math.PI * 2 * Math.random();
      zoom = (Math.round(Math.random() * 100) / 1000) * 2;
      var rayon = zoom / 5;

      bathRP[0].position.x = Math.random() * 0.1 - 0.05;
      bathRP[0].position.y = Math.random() * 0.1 - 0.05;

      bathRP.forEach((rp, index) => {
        if (index > -1) {
          rayon += (Math.random() - 0.5) / 100 / 2;

          bathRP[index].position.x =
            bathRP[0].position.x + Math.cos(angle) * rayon;
          bathRP[index].position.y =
            bathRP[0].position.y + Math.sin(angle) * rayon;
          angle += (Math.PI / 4) * Math.random();
          bathRP[index].rotation.z = angle - Math.PI + Math.random() * Math.PI;
        }
      });
    }

    bathRP.forEach((rp, index) => {
      var sc = range(50, 0, 0, 0.1, Math.abs(rpBase - 50 - index * 10));
      rp.scale.set(sc + zoom, sc + zoom, sc + zoom);
      rp.material.opacity = range(0, 0.1, 0, 0.7, sc);
    });
  }

  const texture = GPUComputation.onFrame(elapsedTime);
  drawingShader.onFrame(elapsedTime, texture);

  stats.end();
  requestAnimationFrame(animate);
}

requestAnimationFrame(animate);

setTimeout(() => {
  document.querySelector(".preloader-white").classList.add("hide");
}, 500);

setTimeout(() => {
  document.querySelector(".intro-screen1").classList.add("hide");
  document.querySelector(".intro-screen2").classList.remove("hide");
}, 5000);

setTimeout(() => {
  document.querySelector(".intro-middle").classList.add("anim");
}, 100);

/**
 * CTA
 */

const start_exp = () => {
  document.querySelector(".intro").classList.add("hide");
  document.querySelector("main").classList.add("show");
  document.querySelector(".cta-start").classList.add("active");
  wh = document.querySelector(".sommaire").clientHeight;
};

document.querySelector(".intro-screen1").addEventListener("click", () => {
  document.querySelector(".intro-screen1").classList.add("hide");
  document.querySelector(".intro-screen2").classList.remove("hide");
});

document.querySelector(".cta-start").addEventListener("click", () => {
  start_exp();
});

document.querySelector("main").addEventListener("scroll", () => {
  document.querySelector(".scroll").classList.add("hide");
});

/**
 * SOUND
 */

const play_sound = () => {

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
      'event': 'open_petit_bambou'
  });

  sound.play();
  soundPlaying = true;
  if(soundBambouPlaying) {
    pause_sound_bambou();
  }
  soundStart = true;
  close_minuteur();

  document.querySelector(".player").classList.remove("hide");
  silence_mode = false;
};

const play_sound_bambou = () => {
  console.log('play_sound_bambou');
  sound_bambou.play();
  soundBambouPlaying = true;
  soundPlaying = false;
  soundBambouStart = true;
  close_minuteur();

  document.querySelector(".player").classList.remove("hide");
  silence_mode = false;
};


const pause_sound = () => {
  console.log('pause experience sound');
  soundPlaying = false;
  sound.pause();


  if(soundBambouPlaying) {
    sound_bambou.pause();
  }

  silence_mode = true;
};

const pause_sound_bambou = () => {
  console.log('pause bambou sound')
  soundBambouPlaying = false;
  sound_bambou.pause();
  sound_bambou.currentTime = 0;
  silence_mode = true;
};

document.querySelector(".cta-play").addEventListener("click", () => {
  if (sound.paused) {
    play_sound();
  } else {
    pause_sound();
  }
  if(soundBambouPlaying) {
    pause_sound_bambou();
    soundBambouPlaying = false;
  }
  document.querySelector(".cta-play").classList.add("active");
});

document.querySelector(".play").addEventListener("click", () => {
  play_sound();
  if(soundBambouPlaying) {
    console.log('cta-play 5');
    pause_sound_bambou();
    soundBambouPlaying = false;
  }
});

document.querySelector(".pause").addEventListener("click", () => {
  pause_sound();
  if(soundBambouPlaying) {
    console.log('cta-play 6');
    pause_sound_bambou();
    soundBambouPlaying = false;
  }
});

const open_player = () => {
  document.querySelector(".player").classList.add("open");
  document.querySelector(".player-cta").classList.add("hide");
  document.querySelector(".player-content").classList.remove("hide");
  document.querySelector(".minuteur").classList.add("hide");
};

const close_player = () => {
  document.querySelector(".player").classList.remove("open");
  document.querySelector(".player-cta").classList.remove("hide");
  document.querySelector(".player-content").classList.add("hide");

  if (minuteurPlaying) {
    document.querySelector(".minuteur").classList.remove("hide");
  }
};

document.querySelector(".player-cta").addEventListener("click", () => {
  console.log('Player experience click');
  if(soundBambouPlaying) {
    console.log('bambou sound already playing, stopping it');
    pause_sound_bambou();
    soundBambouPlaying = false;
    const imagePB = document.getElementById('player-cta-bambou');
    imagePB.src = 'textures/bambou_play.webp';
  }
  if (!soundStart) {
    play_sound();
  } else {
    if (!document.querySelector(".player").classList.contains("open")) {
      open_player();
    }
  }
});

// icone du player du petit bambou
// au click on met sur pause le son de l'expérience si il joue
// et on lance le son de petit bambou
const playerCtaBambou = document.querySelector(".player-cta-bambou");
if(playerCtaBambou){
  playerCtaBambou.addEventListener("click", () => {
    console.log('Player Bambou click');

    if(soundStart || soundPlaying) {
      console.log('experience sound already playing, stopping it');
      sound.pause();
    }
    if (!soundBambouPlaying) {
      console.log('playing Bambou sound');
      const imagePB = document.getElementById('player-cta-bambou');
      imagePB.src = 'textures/bambou_pause.webp';
      play_sound_bambou();
    
    } else {
      const imagePB = document.getElementById('player-cta-bambou');
      imagePB.src = 'textures/bambou_play.webp';
      pause_sound_bambou();
    }
  });
}


document.querySelector(".player-close").addEventListener("click", () => {
  close_player();
});

document.querySelector(".cta-play").addEventListener("animationend", () => {
  document.querySelector(".cta-play").classList.remove("active");
});

document.querySelector(".player-top").addEventListener("click", (e) => {
  var duration = tracks[3].end;
  sound.currentTime = (duration * e.offsetX) / e.currentTarget.offsetWidth;
});
document.querySelector(".player-top").addEventListener("touchmove", (e) => {
  var duration = tracks[3].end;
  sound.currentTime =
    (duration * (e.touches[0].clientX - 50)) / e.currentTarget.offsetWidth;
});

document.querySelector(".next").addEventListener("click", (e) => {
  nTrack++;
  showDuration();
  sound.currentTime = tracks[nTrack].start;
});

document.querySelector(".previous").addEventListener("click", (e) => {
  nTrack--;
  showDuration();
  sound.currentTime = tracks[nTrack].start;
});

/**
 * MINUTEUR
 */

var countdown_run;

const start_countdown = () => {
  document.querySelector(".min-play").classList.add("hide");
  document.querySelector(".min-pause").classList.remove("hide");

  document.querySelector(".min-left").classList.remove("hide");
  document.querySelector(".min-middle").classList.remove("hide");
  document.querySelector(".min-msg").classList.add("hide");

  infusionReady = false;

  countdown_run = setInterval(() => {
    countdown -= 1;

    //minuteur
    var extra = countdown % 60 < 10 ? "0" : "";
    var timeText =
      "0" + Math.floor(countdown / 60) + ":" + extra + (countdown % 60);

    minTime.innerText = timeText;
    minTimeInside.innerText = timeText;

    //fin minuteur
    if (countdown == 0) {
      clearInterval(countdown_run);
      document.querySelector(".min-left").classList.add("hide");
      document.querySelector(".min-middle").classList.add("hide");
      document.querySelector(".min-msg").classList.remove("hide");
      open_minuteur();
      infusionReady = true;
      alarm.play();
    }
  }, 1000);
};

const pause_countdown = () => {
  clearInterval(countdown_run);
  document.querySelector(".min-play").classList.remove("hide");
  document.querySelector(".min-pause").classList.add("hide");
};

const reset_countdown = () => {
  pause_countdown();
  countdown = 300;
  document.querySelector(".min-time-inside").innerHTML = "05:00";
  start_countdown();

  document.querySelector(".min-restart").classList.add("active");
};

const close_minuteur = () => {
  document.querySelector(".min-time").classList.remove("hide");
  document.querySelector(".min-content").classList.add("hide");
  document.querySelector(".minuteur").classList.remove("open");

  if (soundPlaying) {
    document.querySelector(".player").classList.remove("hide");
  }

  if (infusionReady) {
    document.querySelector(".minuteur").classList.add("hide");
    document.querySelector(".cta-minuteur").classList.remove("active");
    countdown = 300;
    minuteurPlaying = false;
  }
};

const open_minuteur = () => {
  document.querySelector(".min-time").classList.add("hide");
  document.querySelector(".min-content").classList.remove("hide");
  document.querySelector(".minuteur").classList.add("open");
  document.querySelector(".player").classList.add("hide");
};

document.querySelector(".cta-minuteur").addEventListener("click", () => {
  alarm.play();
  alarm.pause();

  start_countdown();

  document.querySelector(".cta-minuteur").classList.add("active");
  document.querySelector(".minuteur").classList.remove("hide");
  minuteurPlaying = true;
});

document.querySelector(".min-time").addEventListener("click", () => {
  open_minuteur();
});

document.querySelector(".min-close").addEventListener("click", () => {
  close_minuteur();
});

document.querySelector(".min-restart").addEventListener("click", () => {
  reset_countdown();
});

document.querySelector(".min-controls").addEventListener("click", () => {
  if (document.querySelector(".min-play").classList.contains("hide")) {
    pause_countdown();
  } else {
    start_countdown();
  }
});

document.querySelector(".min-restart").addEventListener("transitionend", () => {
  document.querySelector(".min-restart").classList.remove("active");
});

/**
 * NAV
 */

const open_nav = () => {
  document.querySelector("nav").classList.add("open");

  if (document.querySelector(".nav-links a.active")) {
    document.querySelector(".nav-links a.active").classList.remove("active");
  }

  if (navIndex >= 0) {
    navLinks[navIndex].classList.add("active");
  }
};

const close_nav = () => {
  document.querySelector("nav").classList.remove("open");
};

document.querySelector(".burger-menu").addEventListener("click", () => {
  if (document.querySelector("nav").classList.contains("open")) {
    close_nav();
  } else {
    open_nav();
  }
});

navLinks.forEach((link) => {
  link.addEventListener("click", (e) => {
    e.preventDefault();

    directScroll = true;
    document
      .querySelector("main ." + link.getAttribute("href").substring(1))
      .scrollIntoView();

    start_exp();
    close_nav();
  });
});

document.querySelector(".logo-menu").addEventListener("click", () => {
  document.querySelector(".sommaire").scrollIntoView();
  close_nav();
});

document.querySelector(".logo").addEventListener("click", () => {
  location.reload();
});

/**
 * PREPAREZ
 */

const swiperPrepa = new Swiper(".swiper-prepa", {
  modules: [Pagination],
  centeredSlides: true,
  speed: 500,
  loop: true,
  pagination: {
    el: ".preparez .swiper-pagination",
    type: "bullets",
  },
});

/**
 * REGARDEZ
 */

var context, analyser, source, ctx;
//var equa = document.querySelector('.debug-canvas');

document.querySelector(".cta-regardez").addEventListener("click", () => {
  main.classList.add("smooth");
  document.querySelector(".regardez").scrollIntoView();

  nav.classList.add("closed");
  document.querySelector("main").classList.remove("show");
  document.querySelector(".section-draw").classList.add("show");

  if (sound.paused) {
    silence_mode = true;
  }

  //play_sound();

  setTimeout(() => {
    document.querySelector(".draw-middle").classList.add("hide");
  }, 5000);

  //effectComposer.addPass(displacementPass);
  watchMode = true;

  drawingShader.onStartBath();
  GPUComputation.onStartBath();

  if (firstStart) {
    context = new AudioContext();
    analyser = context.createAnalyser();
    source = context.createMediaElementSource(sound);
    source.connect(analyser);
    analyser.connect(context.destination);
    analyser.fftSize = 2048;

    // ctx = equa.getContext("2d");
    firstStart = false;
  }
  bathPlaying = true;

  //equa.classList.remove('hide');
});

document.querySelector(".cta-close").addEventListener("click", () => {
  document.querySelector("nav").classList.remove("closed");
  document.querySelector("main").classList.add("show");
  document.querySelector(".section-draw").classList.remove("show");
  //equa.classList.add('hide');
  bathPlaying = false;

  //effectComposer.removePass(displacementPass)
  watchMode = false;

  drawingShader.onStopBath();
  GPUComputation.onStopBath();

  bathPink.material.opacity = 0;
  bathElipse1.material.opacity = 0;
  bathElipse2.material.opacity = 0;
  bathWave.material.opacity = 0;
  bathRound.material.opacity = 0;
  bathRound2.material.opacity = 0;

  bathRP.forEach((rp, index) => {
    rp.material.opacity = 0;
  });

  main.classList.remove("smooth");
});

document.querySelector(".section-draw").addEventListener("click", () => {
  document.querySelector(".draw-middle").classList.add("hide");
});

/**
 * SAVOUREZ
 */

document.querySelectorAll(".swiper-slide-round ").forEach((card, index) => {
  var angle = index * 15;
  var angleRad = (angle / 180) * Math.PI;

  card.style.transform = `rotate(${angle}deg)`;
});

const swiper = new Swiper(".savourez-swiper", {
  modules: [Pagination],
  centeredSlides: true,
  speed: 500,
  slidesPerView: 1.6,
  threshold: 5,
  watchSlidesProgress: true,
  pagination: {
    el: ".savourez .swiper-pagination",
    type: "bullets",
  },
});

swiper.on("progress", () => {
  var angle = -swiper.progress * (swiper.slides.length - 1) * 15;
  flowerRound.style.transform = "translateY(1070px) rotate(" + angle + "deg)";
});

swiper.on("touchStart", () => {
  flowerRound.classList.add("direct");
});

swiper.on("touchEnd", () => {
  flowerRound.classList.remove("direct");
});

flowerCards.forEach((card, index) => {
  card.addEventListener("click", () => {
    document.querySelector(".flower-page").classList.remove("hide");

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'open_flowerCard',
        'flower_name': card.querySelector(".flower-title").innerText,
    });

    document.querySelectorAll(".flower-next").forEach((next, i) => {
      if (i == index) {
        document.querySelector(".ff" + i).scrollTop = 0;
        document.querySelector(".ff" + i).classList.remove("hide");
        document.querySelector(".ff" + i).classList.add("direct");
      } else {
        document.querySelector(".ff" + i).classList.add("hide", "direct");
      }
    });
  });
});

document.querySelector(".flower-burger").addEventListener("click", () => {
  document.querySelector(".flower-page").classList.add("hide");
});

document.querySelectorAll(".flower-next").forEach((next, index) => {
  next.addEventListener("click", () => {
    var nextIndex = index < 3 ? index + 1 : 0;

    document.querySelector(".ff" + index).classList.add("hide");
    document.querySelector(".ff" + nextIndex).scrollTop = 0;
    document
      .querySelector(".ff" + nextIndex)
      .classList.remove("hide", "direct");
  });
});

/**
 * RESSENTEZ
 */

var timerC1, timerC2, timerC3;

const timelines1 = document.querySelectorAll(".c1 .timeline");
const timelines2 = document.querySelectorAll(".c2 .timeline");
const timelines3 = document.querySelectorAll(".c3 .timeline");

timelines1.forEach((t, index) => {
  t.style.animationDuration = cTime1[index] + "s";
});

timelines2.forEach((t, index) => {
  t.style.animationDuration = cTime2[index] + "s";
});

timelines3.forEach((t, index) => {
  t.style.animationDuration = cTime3[index] + "s";
});

const swiperC1 = new Swiper(".c1 .swiper", {
  speed: 1500,
});

const swiperC2 = new Swiper(".c2 .swiper", {
  speed: 1000,
});

const swiperC3 = new Swiper(".c3 .swiper", {
  speed: 1000,
});

swiperC1.on("slideChange", () => {
  timerC1 = setTimeout(() => {
    if (document.querySelector(".c1").classList.contains("autoplay")) {
      swiperC1.slideNext();
    }
  }, cTime1[swiperC1.realIndex] * 1000);

  for (var i = 0; i < swiperC1.slides.length; i++) {
    if (i <= swiperC1.activeIndex) {
      if (document.querySelector(".c1").classList.contains("autoplay")) {
        timelines1[i].classList.add("active_play");
      } else {
        timelines1[i].classList.remove("active_play");
        timelines1[i].classList.add("active");
      }
    } else {
      timelines1[i].classList.remove("active");
      timelines1[i].classList.remove("active_play");
    }
  }
});

swiperC2.on("slideChange", () => {
  timerC2 = setTimeout(() => {
    if (document.querySelector(".c2").classList.contains("autoplay")) {
      swiperC2.slideNext();
    }
  }, cTime2[swiperC2.realIndex] * 1000);

  for (var i = 0; i < swiperC2.slides.length; i++) {
    if (i <= swiperC2.activeIndex) {
      if (document.querySelector(".c2").classList.contains("autoplay")) {
        timelines2[i].classList.add("active_play");
      } else {
        timelines2[i].classList.remove("active_play");
        timelines2[i].classList.add("active");
      }
    } else {
      timelines2[i].classList.remove("active");
      timelines2[i].classList.remove("active_play");
    }
  }
});

swiperC3.on("slideChange", () => {
  timerC3 = setTimeout(() => {
    if (document.querySelector(".c3").classList.contains("autoplay")) {
      swiperC3.slideNext();
    }
  }, cTime3[swiperC3.realIndex] * 1000);

  for (var i = 0; i < swiperC3.slides.length; i++) {
    if (i <= swiperC3.activeIndex) {
      if (document.querySelector(".c3").classList.contains("autoplay")) {
        timelines3[i].classList.add("active_play");
      } else {
        timelines3[i].classList.remove("active_play");
        timelines3[i].classList.add("active");
      }
    } else {
      timelines3[i].classList.remove("active");
      timelines3[i].classList.remove("active_play");
    }
  }
});

swiperC1.on("touchStart", () => {
  clearTimeout(timerC1);
  document.querySelector(".c1").classList.remove("autoplay");
});

swiperC2.on("touchStart", () => {
  clearTimeout(timerC2);
  document.querySelector(".c2").classList.remove("autoplay");
});

swiperC3.on("touchStart", () => {
  clearTimeout(timerC3);
  document.querySelector(".c3").classList.remove("autoplay");
});

document.querySelectorAll(".ressentez-item").forEach((item, index) => {
  item.addEventListener("click", (e) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'open_conseil',
        'conseil_name': item.querySelector(".rst-bottom").innerText,
    });

    item.classList.add("visited");
    nav.classList.add("closed");
    main.classList.add("lock");

    var i = index + 1;
    document.querySelector(".c" + i).classList.add("show");

    if (i == 1) {
      if (swiperC1.realIndex == swiperC1.slides.length - 1) {
        swiperC1.slideTo(0, 0);
      }

      if (swiperC1.realIndex == 0) {
        document.querySelector(".c1").classList.add("autoplay");
        timelines1[0].classList.add("active_play");
      }

      timerC1 = setTimeout(() => {
        swiperC1.slideNext();
      }, cTime1[swiperC1.realIndex] * 1000);
    }

    if (i == 2) {
      if (swiperC2.realIndex == swiperC2.slides.length - 1) {
        swiperC2.slideTo(0, 0);
      }

      if (swiperC2.realIndex == 0) {
        document.querySelector(".c2").classList.add("autoplay");
        timelines2[0].classList.add("active_play");
      }

      timerC2 = setTimeout(() => {
        swiperC2.slideNext();
      }, cTime2[swiperC2.realIndex] * 1000);
    }

    if (i == 3) {
      if (swiperC3.realIndex == swiperC3.slides.length - 1) {
        swiperC3.slideTo(0, 0);
      }

      if (swiperC3.realIndex == 0) {
        document.querySelector(".c3").classList.add("autoplay");
        timelines3[0].classList.add("active_play");
      }

      timerC3 = setTimeout(() => {
        swiperC3.slideNext();
      }, cTime3[swiperC3.realIndex] * 1000);
    }
  });
});

document.querySelectorAll(".conseil-close").forEach((cta, index) => {
  cta.addEventListener("click", () => {
    var i = index + 1;
    document.querySelector(".c" + i).classList.remove("show");
    document.querySelector(".c" + i).classList.remove("autoplay");
    main.classList.remove("lock");

    if (i == 1) {
      clearTimeout(timerC1);

      if (swiperC1.realIndex == swiperC1.slides.length - 1) {
        setTimeout(() => {
          timelines1.forEach((timeline) => {
            timeline.classList.remove("active", "active_play");
          });
        }, 500);
      }
    }

    if (i == 2) {
      clearTimeout(timerC2);

      if (swiperC2.realIndex == swiperC2.slides.length - 1) {
        setTimeout(() => {
          timelines2.forEach((timeline) => {
            timeline.classList.remove("active", "active_play");
          });
        }, 500);
      }
    }

    if (i == 3) {
      clearTimeout(timerC3);

      if (swiperC3.realIndex == swiperC3.slides.length - 1) {
        setTimeout(() => {
          timelines3.forEach((timeline) => {
            timeline.classList.remove("active", "active_play");
          });
        }, 500);
      }
    }
  });
});

/**
 * TEXTES
 */

const check = document.querySelector(".observe");

const callback = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.remove("observe");
      myObserver.unobserve(entry.target);
    }
  });
};
const options = {};

const myObserver = new IntersectionObserver(callback, options);
myObserver.observe(document.querySelector(".ecoutez"));
myObserver.observe(document.querySelector(".preparez"));
myObserver.observe(document.querySelector(".regardez"));
myObserver.observe(document.querySelector(".respirez"));
myObserver.observe(document.querySelector(".savourez"));
myObserver.observe(document.querySelector(".ressentez"));
myObserver.observe(document.querySelector(".end"));

var delay = 0;

document.querySelectorAll(".word").forEach((word, index) => {
  delay = (index + 11) / 10;
  word.style.transitionDelay = delay + "s";
});

document.querySelectorAll(".cinq_sens img, .scroll").forEach((img, index) => {
  delay += (index + 2) / 10;
  img.style.transitionDelay = delay + "s";
});

document.querySelectorAll(".word-end , .end-picto").forEach((word, index) => {
  delay = (index + 2) / 10;
  word.style.transitionDelay = delay + "s";
});

/**
 * LAST
 */

const swiperLast = new Swiper(".last-swiper", {
  modules: [Pagination],
  centeredSlides: true,
  speed: 500,
  threshold: 5,
  pagination: {
    el: ".last .swiper-pagination",
    type: "bullets",
  },
});

const lastSlides = document.querySelectorAll('.last-slide');

lastSlides.forEach((slide) => {
  let title = slide.querySelector('.last-slide-title').innerText;
  let experienceCta = slide.querySelector('.buttonExperience');
  let buyCta = slide.querySelector('.buttonBuy');

  experienceCta.addEventListener('click', ()=>{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'clic-collection-xp',
        'nom_produit': title
    })
    console.log(title);
  })
  buyCta.addEventListener('click', ()=>{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'clic-collection-buy',
        'nom_produit': title
    })
  })
  
})