import glsl from "glslify";

export default glsl`

  // Change these values to change the effect
  // You can also try to change values in the main() function below, with less impact on the global effect
  #define BLUR 0.05
  #define RADIUS 0.07

  uniform float time;
  uniform sampler2D inputTexture;
  uniform float mouseX;
  uniform float mouseY;
  uniform float isDrawing;

  float circle(vec2 uv, vec2 center, float radius, float blur) {
    float dist = length(uv - center);
    float circle = smoothstep(radius - blur, radius, dist);
    return 1.0 - circle;
  }

  const vec3 color1 = vec3(236.0, 70.0, 57.0) / 255.0;
  const vec3 color2 = vec3(240.0, 123.0, 75.0) / 255.0;


  void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec4 color = texture2D(inputTexture, uv);

    // compute distance from mouse to current pixel
    vec2 mouse = vec2(mouseX, mouseY) / resolution.xy;
    float dist = distance(uv, mouse);
    float radius = RADIUS + 0.005 * (sin(time * 3.0) + 1.0);
    float c = circle(uv, mouse, radius, BLUR);

    // mix between color1 and color2, based on time
    vec3 colorMix = mix(color1, color2, sin(time));

    color += vec4(0.5 * c * colorMix, 0.0);
    
    if(isDrawing < 0.4) {
      color -= vec4(0.01, 0.01, 0.01, 0.0);
    }

    color -= vec4(0.01, 0.01, 0.01, 0.0);
    // we clamp color (but until 2.0, not 1.0)
    color = clamp(color, 0.0, 2.0);


    gl_FragColor = color;
  }
`;
